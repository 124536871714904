import React, { useState } from "react";
import SearchBar from "../components/SearchBar";
import ProductPricing from "../components/ProductPricing";
import ProfitCalculator from "../components/ProfitCalculator";
import { Helmet } from "react-helmet";
import TrendyolSEOContent from "../components/subComponents/TrendyolSEOContent";
import TrendyolProfitContent from "../components/subComponents/TrendyolProfitContent";
function TrendyolCom() {
  // Ortak state'ler
  const [commissionRate, setCommissionRate] = useState(""); // Trendyol komisyon oranı
  const [finalData, setFinalData] = useState({}); // Tüm hesaplanmış veriler

  return (
    <>
      <Helmet>
        {/* Sayfa Başlığı */}
        <title>Trendyol Komisyon Oranları ve Kâr Hesaplama Aracı</title>

        {/* Meta Açıklama */}
        <meta
          name="description"
          content="Trendyol komisyon oranları ve kargo maliyetlerini öğrenin. Kâr hesaplama aracımızla doğru fiyatlandırma yaparak işletmenizi büyütün. Hemen deneyin!"
        />

        {/* Canonical Link */}
        <link
          rel="canonical"
          href="https://www.pazaryerikomisyon.com/trendyol-komisyon"
        />

        {/* Anahtar Kelimeler */}
        <meta
          name="keywords"
          content="Trendyol komisyon oranları, Trendyol kâr hesaplama, e-ticaret maliyet hesaplama, kargo maliyetleri, Trendyol satış analiz"
        />

        {/* Open Graph Meta Etiketleri */}
        <meta
          property="og:title"
          content="Trendyol Komisyon Oranları ve Kâr Hesaplama"
        />
        <meta
          property="og:description"
          content="Trendyol'da satış yaparken doğru maliyet analizi yapın. Kâr hesaplama aracımızla komisyon oranlarını ve kargo maliyetlerini öğrenin."
        />
        <meta
          property="og:url"
          content="https://www.pazaryerikomisyon.com/trendyol-komisyon"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.pazaryerikomisyon.com/images/trendyol-komisyon.png"
        />

        {/* Twitter Card Meta Etiketleri */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Trendyol Komisyon Oranları ve Kâr Hesaplama"
        />
        <meta
          name="twitter:description"
          content="Trendyol'da satış yaparken kârınızı maksimize edin. Komisyon oranlarını ve kargo maliyetlerini öğrenerek işletmenizi büyütün."
        />
        <meta
          name="twitter:image"
          content="https://www.pazaryerikomisyon.com/images/trendyol-komisyon.png"
        />

        {/* Robots Meta Tag */}
        <meta name="robots" content="index, follow" />

        {/* Ekstra SEO Optimizasyonları */}
        <meta property="og:locale" content="tr_TR" />
        <meta property="og:site_name" content="Pazaryeri Komisyon Hesaplama" />

        {/* JSON-LD Yapılandırılmış Veri */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: "Trendyol Komisyon Oranları ve Kâr Hesaplama",
            description:
              "Trendyol komisyon oranları ve kargo maliyetlerini öğrenin. Kâr hesaplama aracımızla doğru fiyatlandırma yaparak işletmenizi büyütün.",
            url: "https://www.pazaryerikomisyon.com/trendyol-komisyon",
            image:
              "https://www.pazaryerikomisyon.com/images/trendyol-komisyon.png",
            publisher: {
              "@type": "Organization",
              name: "Pazaryeri Komisyon Hesaplama",
              logo: {
                "@type": "ImageObject",
                url: "https://www.pazaryerikomisyon.com/images/logo.png",
              },
            },
          })}
        </script>
      </Helmet>

      <div className="App">
        <SearchBar
          commissionRate={commissionRate}
          setCommissionRate={setCommissionRate}
        />
        <ProductPricing setFinalData={setFinalData} />

        <ProfitCalculator data={finalData} commissionRate={commissionRate} />
        <TrendyolSEOContent />
        <TrendyolProfitContent />
      </div>
    </>
  );
}

export default TrendyolCom;
