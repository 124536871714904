import React, { useState } from "react";
import { Box, TextField, Button, Typography, Container } from "@mui/material";
import DesiImportanceContent from "../components/subComponents/DesiImportanceContent";
const DesiCalculator = () => {
  const [width, setWidth] = useState("");
  const [length, setLength] = useState("");
  const [height, setHeight] = useState("");
  const [desi, setDesi] = useState(null);

  const calculateDesi = () => {
    const w = parseFloat(width) || 0;
    const l = parseFloat(length) || 0;
    const h = parseFloat(height) || 0;
    const result = (w * l * h) / 3000;
    setDesi(result.toFixed(2));
  };

  const resetFields = () => {
    setWidth("");
    setLength("");
    setHeight("");
    setDesi(null);
  };

  return (
    <>
      <Container
        maxWidth="sm"
        sx={{
          padding: "20px",
          marginTop: "20px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          backgroundColor: "#f9f9f9",
        }}
      >
        <Typography variant="h4" align="center" gutterBottom>
          Desi Hesaplama Aracı
        </Typography>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{ display: "flex", flexDirection: "column", gap: "16px" }}
        >
          {/* En */}
          <TextField
            label="En (cm)"
            variant="outlined"
            type="number"
            value={width}
            onChange={(e) => setWidth(e.target.value)}
            fullWidth
          />

          {/* Boy */}
          <TextField
            label="Boy (cm)"
            variant="outlined"
            type="number"
            value={length}
            onChange={(e) => setLength(e.target.value)}
            fullWidth
          />

          {/* Yükseklik */}
          <TextField
            label="Yükseklik (cm)"
            variant="outlined"
            type="number"
            value={height}
            onChange={(e) => setHeight(e.target.value)}
            fullWidth
          />

          {/* Hesaplama Butonu */}
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={calculateDesi}
            sx={{ textTransform: "none" }}
          >
            Desi Hesapla
          </Button>

          {/* Sıfırlama Butonu */}
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            onClick={resetFields}
            sx={{ textTransform: "none" }}
          >
            Alanları Sıfırla
          </Button>
        </Box>

        {/* Sonuç */}
        {desi !== null && (
          <Box
            sx={{
              marginTop: "20px",
              padding: "16px",
              backgroundColor: "#e3f2fd",
              borderRadius: "8px",
              textAlign: "center",
            }}
          >
            <Typography variant="h6">
              <strong>Hesaplanan Desi:</strong> {desi} desi
            </Typography>
          </Box>
        )}
      </Container>
      <DesiImportanceContent />
    </>
  );
};

export default DesiCalculator;
