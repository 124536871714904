import React from "react";
import { Box, Typography } from "@mui/material";

const ProfitCalculator = ({ data, commissionRate }) => {
  const { salesPrice = 0, kdvIncludedPrice = 0, shippingCost = 0 } = data;

  const calculateProfit = () => {
    const trendyolCom =
      (parseFloat(salesPrice) * parseFloat(commissionRate)) / 100;
    const profit =
      parseFloat(salesPrice) -
      parseFloat(trendyolCom) -
      parseFloat(shippingCost) -
      parseFloat(kdvIncludedPrice);

    return profit.toFixed(2);
  };
  const trendyolComPrice = () => {
    const trendyolCom = (parseFloat(salesPrice) * commissionRate) / 100;
    return trendyolCom.toFixed(2);
  };
  const priceRate = () => {
    const trendyolCom =
      (parseFloat(salesPrice) * parseFloat(commissionRate)) / 100;
    const profit =
      parseFloat(salesPrice) -
      parseFloat(trendyolCom) -
      parseFloat(shippingCost) -
      parseFloat(kdvIncludedPrice);

    return ((profit / salesPrice) * 100).toFixed(2);
  };
  const priceRateFromBuy = () => {
    const trendyolCom =
      (parseFloat(salesPrice) * parseFloat(commissionRate)) / 100;
    const profit =
      parseFloat(salesPrice) -
      parseFloat(trendyolCom) -
      parseFloat(shippingCost) -
      parseFloat(kdvIncludedPrice);

    return ((profit / kdvIncludedPrice) * 100).toFixed(2);
  };
  return (
    <Box p={2} display="flex" flexWrap="wrap" gap={2} justifyContent="center">
      <Typography variant="body1">
        <strong>KDV Dahil Fiyat:</strong> {kdvIncludedPrice} TL
      </Typography>
      <Typography variant="body1">
        <strong>Kargo Ücreti:</strong> {shippingCost} TL
      </Typography>
      <Typography variant="body1">
        <strong>Satış Fiyatı:</strong> {salesPrice} TL
      </Typography>
      <Typography variant="body1">
        <strong>Net Kar:</strong> {calculateProfit()} TL
      </Typography>
      <Typography variant="body1">
        <strong>Trendyol Komisyon</strong> {trendyolComPrice()} TL
      </Typography>
      <Typography variant="body1">
        <strong>Satıştan Kar Oranı </strong> {priceRate()} %
      </Typography>
      <Typography variant="body1">
        <strong>Alıştan Kar Oranı </strong> {priceRateFromBuy()} %
      </Typography>
    </Box>
  );
};

export default ProfitCalculator;
