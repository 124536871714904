import React from "react";
import { CssBaseline, Skeleton, Grid } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import HomePage from "./Pages/TrendyolCom";
import KDVCalculator from "./Pages/KDVCalculator";
import FAQTabs from "./components/FAQTabs";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import DesiCalculator from "./Pages/DesiCalculator";
import usePageTracking from "./components/googleComs/usePageTracking";
import useGTMTracking from "./components/googleComs/useGTMTracking";
const muiCache = createCache({
  key: "mui",
  prepend: true, // Kritik CSS'nin daha hızlı yüklenmesi için
});

const App = () => {
  usePageTracking();
  useGTMTracking();
  return (
    <CacheProvider value={muiCache}>
      {/* CSS Temizleme ve Varsayılan Stiller */}
      <CssBaseline />

      {/* Tüm İçerik Wrapper */}

      {/* Header */}
      <React.Suspense
        fallback={
          <Skeleton
            variant="rectangular"
            width="100%"
            height={64}
            animation="wave"
          />
        }
      >
        <Header />
      </React.Suspense>

      {/* Ana İçerik */}
      <Grid container>
        {/* Sol Yan Alan (Reklamlar) */}
        {
          // <Grid
          //   item
          //   xs={12}
          //   sm={2}
          //   sx={{
          //     backgroundColor: "#f4f4f4",
          //     padding: 2,
          //     borderRight: "1px solid #ddd",
          //     textAlign: "center",
          //   }}
          // ></Grid>
        }
        {/* Ana Sayfa ve Diğer Sayfalar */}
        <Grid
          item
          xs={12}
          sm={8}
          sx={{
            padding: { xs: 2, sm: 4 },
          }}
        >
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/kdv-calculator" element={<KDVCalculator />} />
            <Route path="/trendyol-komisyon-hesapla" element={<HomePage />} />
            <Route path="/desi-hesapla" element={<DesiCalculator />} />
            <Route path="/faq" element={<FAQTabs />} />
          </Routes>
        </Grid>

        {/* Sağ Yan Alan (Reklamlar) */}
        {/* <Grid
            item
            xs={12}
            sm={2}
            sx={{
              backgroundColor: "#f4f4f4",
              padding: 2,
              borderLeft: "1px solid #ddd",
              textAlign: "center",
            }}
          >
            <p>Reklam Alanı</p>
          </Grid> */}
      </Grid>
    </CacheProvider>
  );
};

export default App;
