import React, { useState, useEffect } from "react";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

const kargoFiyatlari = {
  "0-124.99": { PTT: 19.58, Aras: 25.83, YK: 43.33 },
  "125-199.99": { PTT: 37.49, Aras: 44.99, YK: 59.58 },
};

const fetchDesiData = async () => {
  try {
    const response = await fetch("/desiKargo.json");
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Desi verisi alınırken hata oluştu:", error);
    return [];
  }
};

const ProductPricing = ({
  setFinalData, // Parent komponentten gelen, tüm hesaplanmış verileri gönderir
}) => {
  const [purchasePrice, setPurchasePrice] = useState(""); // Geliş fiyatı
  const [isKdvIncluded, setIsKdvIncluded] = useState(false); // KDV dahil mi
  const [kdvRate, setKdvRate] = useState(""); // KDV oranı
  const [salesPrice, setSalesPrice] = useState(""); // Satış fiyatı
  const [desi, setDesi] = useState(""); // Desi bilgisi
  const [baremAlti, setBaremAlti] = useState(false); // Barem altı seçeneği
  const [selectedShipping, setSelectedShipping] = useState(""); // Seçilen kargo firması
  const [desiKargoFiyatlari, setDesiKargoFiyatlari] = useState([]); // Desi tablosu

  useEffect(() => {
    const loadDesiData = async () => {
      const data = await fetchDesiData();
      setDesiKargoFiyatlari(data);
    };
    loadDesiData();
  }, []);

  const getShippingOptions = () => {
    if (baremAlti && parseFloat(salesPrice) < 200) {
      return Object.keys(kargoFiyatlari["0-124.99"]);
    }
    return Object.keys(desiKargoFiyatlari[0] || {}).filter(
      (key) => key !== "Desi_KG"
    );
  };

  const getShippingPrice = () => {
    const desiValue = parseFloat(desi) || 0;
    const price = parseFloat(salesPrice) || 0;

    if (baremAlti && price < 200) {
      if (price < 125) return kargoFiyatlari["0-124.99"][selectedShipping] || 0;
      if (price < 200)
        return kargoFiyatlari["125-199.99"][selectedShipping] || 0;
    }

    const matchingRow = desiKargoFiyatlari.find(
      (row) => row.Desi_KG === desiValue
    );
    return matchingRow ? matchingRow[selectedShipping] || 0 : 0;
  };

  const calculateKdvIncludedPrice = () => {
    const basePrice = parseFloat(purchasePrice) || 0;
    const kdv = isKdvIncluded ? basePrice * (kdvRate / 100) : 0;
    return (basePrice + kdv).toFixed(2);
  };

  const calculateShippingWithKdv = () => {
    const shippingPrice = getShippingPrice();
    return (shippingPrice * 1.2).toFixed(2);
  };

  useEffect(() => {
    const shippingCost = calculateShippingWithKdv();
    const kdvIncludedPrice = calculateKdvIncludedPrice();

    // Tüm hesaplanmış veriler Parent'e gönderiliyor
    setFinalData({
      purchasePrice,
      salesPrice,
      kdvIncludedPrice,
      shippingCost,
      selectedShipping,
      kdvRate,
    });
  }, [
    purchasePrice,
    salesPrice,
    isKdvIncluded,
    kdvRate,
    desi,
    selectedShipping,
    baremAlti,
  ]);

  return (
    <Box p={2} display="flex" alignItems="center" gap={2} flexWrap="wrap">
      {/* Geliş Fiyatı */}
      <TextField
        label="Ürün Maliyeti "
        variant="outlined"
        type="number"
        value={purchasePrice}
        onChange={(e) => setPurchasePrice(e.target.value)}
        sx={{ width: 150 }}
      />

      {/* KDV Dahil Mi */}
      <FormControlLabel
        control={
          <Checkbox
            checked={isKdvIncluded}
            onChange={(e) => {
              setIsKdvIncluded(e.target.checked);
              if (!e.target.checked) setKdvRate("");
            }}
          />
        }
        label="+ KDV"
      />

      {/* KDV Oranı Seçimi */}
      <FormControl sx={{ width: 100 }}>
        <InputLabel>KDV Oranı</InputLabel>
        <Select
          value={kdvRate}
          onChange={(e) => setKdvRate(e.target.value)}
          disabled={!isKdvIncluded}
        >
          <MenuItem value={0}>%0</MenuItem>
          <MenuItem value={10}>%10</MenuItem>
          <MenuItem value={20}>%20</MenuItem>
        </Select>
      </FormControl>

      {/* Satış Fiyatı */}
      <TextField
        label="Brüt Satış Fiyatı"
        variant="outlined"
        type="number"
        value={salesPrice}
        onChange={(e) => setSalesPrice(e.target.value)}
        sx={{ width: 150 }}
      />

      {/* Desi Hesabı */}
      <TextField
        label="Desi"
        variant="outlined"
        type="number"
        value={desi}
        onChange={(e) => setDesi(e.target.value)}
        sx={{ width: 100 }}
      />

      {/* Barem Altı Seçeneği */}
      <FormControlLabel
        control={
          <Checkbox
            checked={baremAlti}
            onChange={(e) => setBaremAlti(e.target.checked)}
          />
        }
        label="Barem Altı"
      />

      {/* Kargo Firması Seçimi */}
      <FormControl sx={{ width: 150 }}>
        <InputLabel>Kargo Firması</InputLabel>
        <Select
          value={selectedShipping}
          onChange={(e) => setSelectedShipping(e.target.value)}
        >
          {getShippingOptions().map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Özet Alanı */}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        gap={1}
        mt={2}
      >
        <Typography variant="body1">
          <strong>KDV Dahil Alış Fiyatı:</strong> {calculateKdvIncludedPrice()}{" "}
          TL
        </Typography>
        {selectedShipping && (
          <Typography variant="body1">
            <strong>Kargo Ücreti (KDV Dahil):</strong>{" "}
            {calculateShippingWithKdv()} TL
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ProductPricing;
