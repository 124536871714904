import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useGTMTracking = () => {
  const location = useLocation();

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "pageview",
      page_path: location.pathname + location.search,
    });
  }, [location]);
};

export default useGTMTracking;
