import React, { useEffect, useState } from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";

const FAQTabs = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    // Schema Markup oluşturma
    const faqSchema = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "Trendyol komisyon oranları nasıl belirlenir?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Trendyol komisyon oranları ürün kategorisine göre değişiklik gösterir. Örneğin, elektronik kategorisinde %12, giyim kategorisinde %20 oranında komisyon uygulanır.",
          },
        },
        {
          "@type": "Question",
          name: "Hepsiburada komisyon oranları nasıl hesaplanır?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Hepsiburada'da komisyon oranları kategoriye göre farklılık gösterir. Giyim için %20, elektronik için %10 oranında komisyon uygulanır.",
          },
        },
      ],
    };

    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.text = JSON.stringify(faqSchema);
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  // Sıkça Sorulan Sorular
  const faqs = [
    {
      category: "Trendyol",
      questions: [
        {
          question: "Trendyol komisyon oranları nasıl belirlenir?",
          answer:
            "Trendyol komisyon oranları ürün kategorisine göre değişiklik gösterir. Örneğin, elektronik kategorisinde %12, giyim kategorisinde %20 oranında komisyon uygulanır.",
        },
        {
          question: "Trendyol'da komisyon oranları düşürülebilir mi?",
          answer:
            "Trendyol ile yapılan özel anlaşmalar sonucunda büyük hacimli satış yapan mağazalar için komisyon oranları düşürülebilir.",
        },
      ],
    },
    {
      category: "Hepsiburada",
      questions: [
        {
          question: "Hepsiburada komisyon oranları nasıl hesaplanır?",
          answer:
            "Hepsiburada'da komisyon oranları kategoriye göre farklılık gösterir. Giyim için %20, elektronik için %10 oranında komisyon uygulanır.",
        },
        {
          question: "Hepsiburada'da kargo ücretleri nasıl hesaplanır?",
          answer:
            "Hepsiburada'da kargo ücretleri ürün ağırlığı ve gönderim mesafesine göre değişiklik gösterir.",
        },
      ],
    },
    {
      category: "Genel Sorular",
      questions: [
        {
          question: "Pazar yerlerinde satış yapmanın avantajları nelerdir?",
          answer:
            "Pazar yerleri geniş bir müşteri kitlesine ulaşmanızı sağlar. Ayrıca reklam, lojistik ve müşteri hizmetleri desteği sunar.",
        },
        {
          question: "Komisyon oranları kategoriye göre değişir mi?",
          answer:
            "Evet, çoğu pazar yerinde komisyon oranları ürün kategorisine göre değişiklik gösterir.",
        },
      ],
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "800px",
        margin: "0 auto",
        padding: "20px",
      }}
    >
      <Typography
        variant="h4"
        sx={{ marginBottom: "20px", textAlign: "center" }}
      >
        Sıkça Sorulan Sorular
      </Typography>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="FAQ Tabs"
        sx={{ marginBottom: "20px", borderBottom: "1px solid #ddd" }}
      >
        {faqs.map((faq, index) => (
          <Tab label={faq.category} key={index} />
        ))}
      </Tabs>
      <Box>
        {faqs[activeTab]?.questions.map((q, index) => (
          <Box key={index} sx={{ marginBottom: "20px" }}>
            <Typography variant="h6">{q.question}</Typography>
            <Typography variant="body1">{q.answer}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default FAQTabs;
