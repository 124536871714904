import React, { useState } from "react";
import {
  Box,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import KDVSEOContent from "../components/subComponents/KDVSEOContent";
const KDVCalculator = () => {
  const [price, setPrice] = useState(""); // Kullanıcının girdiği fiyat
  const [kdvRate, setKdvRate] = useState(18); // Varsayılan KDV oranı

  const calculateWithKDV = () => {
    const basePrice = parseFloat(price) || 0;
    const kdvAmount = (basePrice * kdvRate) / 100;
    return (basePrice + kdvAmount).toFixed(2);
  };

  const calculateWithoutKDV = () => {
    const totalPrice = parseFloat(price) || 0;
    const kdvAmount = totalPrice - totalPrice / (1 + kdvRate / 100);
    return {
      basePrice: (totalPrice - kdvAmount).toFixed(2),
      kdvAmount: kdvAmount.toFixed(2),
    };
  };

  return (
    <>
      <Box
        p={4}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={3}
        textAlign="center"
      >
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          KDV Hesaplama
        </Typography>

        {/* Fiyat Girişi */}
        <TextField
          label="Fiyat"
          type="number"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          sx={{ width: 300 }}
        />

        {/* KDV Oranı Seçimi */}
        <FormControl sx={{ width: 300 }}>
          <InputLabel>KDV Oranı</InputLabel>
          <Select value={kdvRate} onChange={(e) => setKdvRate(e.target.value)}>
            <MenuItem value={0}>%0</MenuItem>
            <MenuItem value={1}>%1</MenuItem>
            <MenuItem value={8}>%8</MenuItem>
            <MenuItem value={10}>%10</MenuItem>
            <MenuItem value={18}>%18</MenuItem>
            <MenuItem value={20}>%20</MenuItem>
          </Select>
        </FormControl>

        {/* Hesaplama Sonuçları */}
        {price && (
          <Box
            mt={3}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap={1}
            p={2}
            border="1px solid #ddd"
            borderRadius="8px"
            sx={{ width: 300, backgroundColor: "#f9f9f9" }}
          >
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              KDV Dahil Fiyat: {calculateWithKDV()} TL
            </Typography>
            <Typography variant="body1">
              KDV Hariç Fiyat: {calculateWithoutKDV().basePrice} TL
            </Typography>
            <Typography variant="body1">
              KDV Tutarı: {calculateWithoutKDV().kdvAmount} TL
            </Typography>
          </Box>
        )}
      </Box>
      <KDVSEOContent />
    </>
  );
};

export default KDVCalculator;
