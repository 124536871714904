import React, { useState } from "react";
import "./Header.css";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header">
      <a href="/" className="header-logo">
        Pazaryeri Komisyon Hesaplama
      </a>

      <nav className={`header-nav ${menuOpen ? "open" : ""}`}>
        <a href="/" className="header-link">
          Ana Sayfa
        </a>
        <a href="/kdv-calculator" className="header-link">
          KDV Hesaplama
        </a>
        <a href="/trendyol-komisyon-hesapla" className="header-link">
          Trendyol Komisyon Hesaplama
        </a>
        <a href="/desi-hesapla" className="header-link">
          Desi Hesaplama
        </a>
        <a href="/faq" className="header-link">
          SSS
        </a>
      </nav>

      <button className="header-menu" aria-label="menu" onClick={toggleMenu}>
        ☰
      </button>
    </header>
  );
};

export default Header;
