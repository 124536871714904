import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Box,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";

const SearchBar = ({ commissionRate, setCommissionRate }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [commissionsData, setCommissionsData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  // JSON dosyasını çekme
  useEffect(() => {
    const fetchCommissions = async () => {
      try {
        const response = await fetch("/commissions.json"); // JSON dosyanızın yolu
        const data = await response.json();
        setCommissionsData(data);
      } catch (error) {
        console.error("Veriler alınırken hata oluştu:", error);
      }
    };

    fetchCommissions();
  }, []);

  const handleSearch = () => {
    const query = searchQuery.toLowerCase();
    const results = commissionsData.filter(
      (item) =>
        (item.subCategory && item.subCategory.toLowerCase().includes(query)) ||
        (item.productGroup && item.productGroup.toLowerCase().includes(query))
    );
    setFilteredData(results);
    setAnchorEl(null);
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSelectItem = (item) => {
    setSelectedItem(item);
    setCommissionRate(item.commissions); // Komisyon oranını dışarı aktarma
    handleCloseMenu();
  };

  return (
    <Box p={4}>
      <Box display="flex" alignItems="center" gap={2} mb={2}>
        <TextField
          label="Ara"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          fullWidth
        />
        <Button
          variant="contained"
          color="primary"
          onClick={(e) => {
            handleSearch();
            handleOpenMenu(e);
          }}
        >
          Ara
        </Button>
      </Box>

      {/* Açılır Menü */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {filteredData.length > 0 ? (
          filteredData.map((item, index) => (
            <MenuItem key={index} onClick={() => handleSelectItem(item)}>
              {item.category} - {item.subCategory}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>Sonuç bulunamadı</MenuItem>
        )}
      </Menu>

      {/* Seçilen Ürün Gösterimi */}
      {selectedItem && (
        <Typography mt={2} variant="body1">
          <strong>Seçilen Ürün:</strong> {selectedItem.category} /{" "}
          {selectedItem.subCategory} / {selectedItem.productGroup} / %{" "}
          {selectedItem.commissions}
        </Typography>
      )}
    </Box>
  );
};

export default SearchBar;
